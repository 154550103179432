 .container {
    width: 100%;
    background: #F6F8FA;
    margin: 0 auto;
    margin-bottom: 50px;
    display:flex ;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* max-width: 100%; */
    /* margin-top: 50px; */
}
.slider-main{
    display: flex;
    gap: 5px;
}
.main-card {
    width: 100%;
    background-color: none;
   display: flex;
    gap: 100px;
    /* padding: 0 20px; */
    padding: 0  150px 150px 150px;
    margin-top: -50px;
    /* margin-left: 20px; */
    margin-left:auto;
    margin-right: auto;
}
/* .slick-slider{
    width: 100%;
} */

.icon-flex{
    display: flex;
    gap: 3px;
}
.home-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
    text-align: center;
}
.home-title h5{
    margin-top: -5px;
    font-weight: 300;
}

.card {
    background: #FFFFFF;
    padding: 5px;
    /* margin: 10px; */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    max-width: 95%;
    /* max-width: 300px ; */
    width: 100%;
    /* min-width: 60%; */
    flex: 1;
    height: 100%;
}

.card-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    /* object-fit: cover; */
}

.card-content {
    display: flex;
    flex-direction: column;
    padding: 15px;
}
/* .slick-prev, .slick-next {
    display: block !important; 
    width: 40px;
  
    border-radius: 17px;
    z-index: 20;
    top: 50%;

    position: absolute;
  }

.slick-prev:before, .slick-next:before {
    color: black;
    background-color: lightgray; 
    border-radius: 17px;
    width: 40px;
    height: 40px;
    padding: 10px 15px;
}

  
  .slick-prev {
    left: -5px; 
    margin-left: 50px;
  
  }
  
  .slick-next {
    right: -5px;
    margin-right: 80px; 
  }
  .slick-prev:hover, .slick-next:hover {
  background-color: gray;
  color: black;
} */

/* .nextarrow{
    display: block;
    background: lightslategrey;
    top: 50%;
    right: 20%;
} */
.slick-dots {
    position: absolute;
 
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-top: 0px; 
    top: 70%;
    right: -1%;
    list-style: none;
    padding: 10px;
  }
  
  .slick-dots li.slick-active button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
   
    border:1px solid gray;
  }
  
  .slick-dots li.slick-inactive button{
   border: none;
  }
  .slick-dots li button:hover{
   background-color: gray;
   border-radius: 50%;
  }

.card-content-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-cost {
    color: red;
}

.card-location {
    display: flex;
    align-items: center;
    margin-top: -15px;
    gap: 10px;
}
.card-location h5{
    font-weight: 300;
}

.card-content-icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: -10px; 
    gap: 20px;
   
}

.card-content-icons h5 {
    display: flex;
    align-items: center;
    gap:10px;
    margin-right: 10px; 
    font-weight: 300;
}

.container2 {
    width: 100%;
    background: #FFFFFF;
    margin: 0 auto;
    max-width: 100%;
     margin-bottom: 50px;
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    margin-top: -40px;
  
    
}
.container2-2 {
    width: 100%;
    background: #FFFFFF;
    margin: 0 auto;
    max-width: 100%;
     margin-bottom: 50px;
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    margin-top: -80px;
  
    
}
.main-container2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;   
    justify-content: center;
    gap: 20px;
    margin-top: -40px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
}

.top-links, 
.bottom-links {
    display: flex;

    justify-content: center; 
    align-items: center;     
    gap: 20px;               
    width: 100%;
}

.link-image {
    width: 48%; 
    max-width: 250px; 
    height: auto;
}
.link-image1 {
    width: 50%; 
    max-width: 250px; 
    height: auto;
}

.link-image2 {
    width: 50%;
    max-width: 520px;
    height: auto;
}
.top-links-side{
    display: flex;
    gap: 20px;
}

.container3 {
    width: 95%;
    background: #E7C873;
    margin: 50px auto;
    max-width: 100%;
    border-radius: 30px;
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
     padding-bottom: 100px;
    
}
.icon-container{
    display: flex;
 
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    padding: 100px;
    gap: 50px;
    margin: -100px 0;
}
.icon-container h5{
    /* font-size: 13px; */
    font-weight: 300;
}
.icon-container h4{
    /* font-size: 18px; */
    margin-bottom: 0;
}
.icon-image-card{
    width: 25%;
 
 text-align: center;
}
.image-links{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.main-container3 {
    width: 100%;
    display: flex;
    margin-top: -40px;
    align-items: center;   
    justify-content: center;
    gap: 20px;
}
.image4{
    width: 100%;
    max-width: 520px;
    height: auto;

}
.image4-img{
    width: 100%;
    border-radius: 10px;
}
.container4{
    width: 98%;
    background: #1F4B43;
    margin: 50px auto;
    max-width: 100%;
    border-radius: 30px;
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
  
}
.container4-top-flex{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5%;
    width: 90%;
    /* margin: auto 30%; */
    gap: 0;
    padding-bottom: 1%;
    /* margin-left: 50%; */
    /* margin-right: 20%; */

}
.container4-side{
    width: 55%;
  color: #FFFFFF;
  font-family: Roboto;
  margin-right: 10%;
  margin-left:0%;
}
.container4-side h3{
    width: 50%;
}
.container4-side h6{
    
    font-weight: 300;
}
.container4-side button{
 background: #E7C873;
 padding: 10px;
 border-radius: 10px; 
 border: #E7C873;   

}
.clienttag{
    position: absolute;
     /* top: 592%;
    left:23%;
    width: 8%;  */
    top: 10px; /* Adjust to position vertically */
    left: 10px; /* Adjust to position horizontally */
    width: 25%; /* Adjust size as needed */
    /* height: 40px; */
    width: 20%;
}
.image-wrapper {
    width: 100%;
    position: relative;
    display: inline-block;
    margin-left: 7%;
  }
.image8{

    width: 80%;
    height: auto;
  
}
.container4-bottom{

        display: flex;
        justify-content:space-around;
        align-items: center;
        gap: 150px;
        text-align: center;
        margin-bottom: 20px;
}
.container4-bottom h2{
    color: #E7C873;
    margin-bottom: -15px;
}
.container4-bottom h6{
    color: #FFFFFF;
}
.container5{
    width: 100%;
    background: #FFFFFF;
    margin: 0 auto;
    max-width: 100%;

    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    gap: 50px;
}
.container5-top{
    display: flex;
    width: 80%;
    gap: 200px;
    margin-left: 80px;
}
.container5-top h5{
    font-weight: 300;
    width: 70%;
    
}
.container5-flex{
    display: flex;
    gap: 20px;
    
}
.container5-flex h5{
    font-weight: 300;
    width: 100%;
    margin-top: -15px;
}
.container5-top-side{
    width: 20%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    text-align: left;
    gap: 15px;
 
}
.container5-imgdiv{
    margin-top: 20px;
}
.container5-imgdiv h6{
    font-weight: 300;
}
.container5-div{
    
    display: flex;
    align-items: center;
    justify-content: center;
}
.container5-bottom{
    text-align: center;
    border-top: 1px solid lightgray;
    /* margin: 20px 0; */
    margin-bottom: 60px;
    padding-top: 50px;

}
.container5-bottom h4{
    font-weight: 300;
    margin-bottom: 30px;
}
.container5-bottom-images{
    display: flex;
    gap: 100px;
    justify-content: space-between;
    align-items: center;
}
.container5-p{
    line-height: 1.2;
    /* text-align: flex-start; */
    display: inline;
    white-space: nowrap;
    margin-top: 100px;
}
.container5-div h6{
 margin-top:5px ;

}
.left-custome-button{
    display: block;
    background-color: lightgray;
    border-radius: 17px;
    color: black;
    width: 40px;
    height: 30px;
    z-index: 10;
    left: 7%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    cursor: pointer;
}
.right-custom-button{
    display: block;
    background-color: lightgray;
    border-radius: 17px;
    color: black;
    width: 40px;
    height: 30px;
    z-index: 10;
    right: 8%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    cursor: pointer;
}
.slick-slide > div {
    margin: 0 10px;
    padding: 0;

}

@media screen and (max-width: 1200px) {
    .main-card {
        /* flex-direction: column;  */
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        /* padding: 5px; */
        margin-bottom: 20px;
        margin-top: -45px;
        width: 100%;
    }
    .card{
        /* min-width: 400px; */
        height: auto;
    }
    .card-content-icons{
        gap: 7px;
    }
    .card-content-icons h5{
        align-items: center;
        gap: 0;
    }
    .card-title{
        font-size: 15px;
    }
   
    .top-links, 
    .bottom-links {
        flex-direction: column; 
        justify-content: center;
        align-items: center; 
        gap: 20px; 
        width: 100%;
    }
    .link-image2 {
        width: 100%;
       
        height: auto;
    }
    .icon-container{
        display: flex;
        flex-direction: column;
        
    }
    .container3{
        width: 60%;
    }
    .main-container3 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;    
        justify-content: center;
        gap: 20px;
    }
    .container4-top-flex{
        width: 100%;
        flex-direction: column;
    }
    .container4-side{
        width: 50%;
    }
    .container4{
        width: 85%;
       
        object-fit: cover;
    }
    .container4-bottom{
        gap: 5px;

    }
    .container4-bottom h1{
       font-size: 10px;
    }
    .container5-top{
        flex-direction: column;
        width: 90%;
        gap: 10px;
      
    }
    .icon-image-card{
        width: 100%;
    }
    .container5-bottom-images{
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .container5{
        width: 65%;
       
    }
    .container{
        margin-bottom: 30px;
    }
    .container2{
        margin-top: 0;
    }
    .slick-dots{
        top: 60%;
        
    }
    .image-wrapper{
        width: 68%;
    }
    /* .clienttag{
        position: absolute;
         top: 840%;
        left: 28%;
        width: 8%; 
    } */
     .container4{
        width: 60%;
     }
} 



@media screen and (max-width: 750px) {
    .slick-slide > div {
        margin: 0;
    }
    .container{
        width: 100%;
        padding-bottom: 50px;
    }
    .main-card {
        /* flex-direction: column;  */
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        padding: 0;
        width: 70%;
        margin-bottom: 30px;
        /* margin-left: 30px; */
        margin-top: -45px;
    }
    .card{
  width: 95%;
  padding: 10px;

  /* margin: -7px; */
    }
    .card-content-icons{
        gap: 10px;
    }
    .header-icon{
        width: 20%;
    }
    /* .slick-prev{
        left: -10px;
    }
    .slick-next{
        margin-right: 80px;
    } */
    .link-image{
        width: 48%;
    }
    .link-image1{
        width: 48%;
    }
   
    .top-links, 
    .bottom-links {
        flex-direction: column; 
        justify-content: center;
        align-items: center; 
        gap: 20px; 
        width: 95%;
    }
    .link-image2 {
        width: 100%;
       
        height: auto;
    }
    .icon-container{
        width: 90%;
        display: flex;
        flex-direction: column;
        
    }
    .container3{
        padding: 5%;
        width: 70%;
        max-width: 100%;
    }
    .main-container3 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;    
        justify-content: center;
        gap: 20px;
    }
    .container4-top-flex{

        flex-direction: column;
    }
    /* .container4-side{
        width: 65%;
        margin-left: 20px;
    }*/
     .container4{
        width: 80%;
       
        /* object-fit: cover; */
    } 
    .container4-bottom{
        gap: 10px;

    }
    .container4-bottom h2{
       font-size: 16px;
    }
    .container4-bottom h6{
        font-size: 7px;
    }
    .container5-top{
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        gap: 10px;
      
    }
    .icon-image-card{
        width: 50%;
    }
    .container5-bottom-images{
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    /* .container5{
        width: 100%;
       
    } */
    /* .clienttag {
       width: 50px;
    } */
    .slick-dots{
        top: 105%;
        /* margin-bottom: 20px; */
    }
    /* .top-links-side{
        width: 100%;
        gap:10px;
    } */
    .image4-img{
        width: 95%;
    }
    .image-links{
        width: 95%;
        align-items: center;
    }
    .container7-bottom button {
        width: 100%;
    }
    .main-container2{
        gap: 20px;
    }
    .card-content-icons{
        gap: 0px;
    }
} 

@media screen and (max-width:560px){
    .container3{
        width: 85%;
    }
    .container4{
        width: 95%;
    }
    .container5{
        width: 100%;
    }
}

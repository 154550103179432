.main {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative; 
}


.homepage {
  width: 100%;           
  height: 100%;        
  object-fit: cover;     
}


.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  color: #ffffff;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
 
}

.header-left {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left:20px;
}

.header-middle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-grow: 1; 
  min-width: 0; 
  padding-left: 100px;
}

.header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding-right: 20px;
}
.callicondiv{
  display: flex;
  align-items: center;
}

.header-right img {
  max-width: 30px; 
  height: auto;
}

.header-right button {
  background: none;
  border: 1px solid #ffffff;
  color: #ffffff;
  padding: 8px;
  border-radius: 20px;
  cursor: pointer;
}

.header-link {
  display: flex;
  align-items: center;
}


.burger-icon {
  display: none; 
  font-size: 24px;
  cursor: pointer;
  padding-right: 20px;
}


.header-dropdown {
  display: none; 
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.95); 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  z-index: 10; 
  position: fixed; 
  top: 0;
  left: 0; 
  width: 100vw; 
  height: 100vh; 
  color: black;
}
.header-link-dropdown{
  display: flex;
  margin: 30px 0;
  width: 80vw;
  justify-content: space-between;
}
.dropdown-bottom{
  display: flex ;
  flex-direction: column;
  gap: 20px;
}
.dropdown-bottom img{
 width: 40px;
}
.dropdown-bottom button{
  width: 50%;
  border-radius: 20px;
  padding: 10px;
}
.header-dropdown-content{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin:  10px 20px;

}
.header-dropdown-item{
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  align-items: center;
}
.header-left-dropdown {
  display: flex;
  align-items: center;
  gap: 10px;
 
}
.crossicon{
  width: 30px;
  height: 30px;
}

a {
  text-decoration: none;
  color: #ffffff;
}
a:hover{
  color: red;
}

 .homepage-middle {
  width: 90%;
  max-width: 500px;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%); 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.homepage-middle button {
  background-color: transparent;
  color: #ffffff;
  border-radius: 20px;
  border: 1px solid #ffffff;
  padding: 10px 20px;
  font-size: 0.5rem; 
}

.homepage-middle h1 {
  font-size: 2rem; 
  text-align: center;
  font-weight: 600;
  margin: 20px 0;
  width: 70%;
}

.homepage-options {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  font-size: 1rem; 
  font-weight: 200;
}

.option1 {
  border-bottom: 1px solid #ffffff;
}

.searchbar {
  width: 100%;
  max-width: 500px; 
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background-color: #ffffff;
  padding: 3px 10px;
  padding-right: 1px;
  margin: 15px 0;
}

.searchbar input {
  width: 90%;
  border: none;
  color: black;
}

.searchbar img {
  background-color: #E7C873;
  padding: 10px;
  border-radius: 50px;
}


@media screen and (max-width: 1000px) {
  .homepage-middle {
    top: 17%; 
    width: 70%; 
  }

  .homepage-middle h1 {
    font-size: 1.5rem;
  }

  .homepage-middle button {
    
    font-size: 0.4rem;
    padding: 3px;
  }

  .homepage-options {
    gap: 15px; 
    font-size: 0.775rem; 
  }

  .searchbar {
    height: 15px;
    width: 60%;
  }

  .searchbar img {
    padding: 0px; 
  }

}


@media screen and (max-width: 550px) {
  .homepage-middle {
    top: 2%;
    /* width: 100%;  */
    gap: -1rem;
  }
}


@media screen and (max-width: 1200px) {
  .header-middle, .header-right {
    display: none; 
  }
  

  .burger-icon {
    display: block;
  }

  .header-dropdown {
    display: flex; 
  }
  .header-dropdown {
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: black; 
    position: fixed;
   
    color: white;
    
   
    width: 100vw;
    height: 100vh;
     
    
    z-index: 100; 
  }
  .searchbar img{
    width: 15px;
  }
}

@media screen and (max-width: 700px) {
  .header {
    padding: 0;
    gap: 10px; 
  }

  .header-left h3 {
    font-size: 16px;
  }

  .header-icon {
    width: 25px; 
  }

  .header-right {
    gap: 5px; 
  }

  .header-right p {
    display: none; 
  }

  .header-right img {
    max-width: 25px;
  }

  .header-right button {
    display: none; 
  }

  .homepage {
    height: auto; 
    max-height: 100vh;    
    object-fit: contain; 
  }
  .header-dropdown {
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color:black; 
    position: fixed;
    color: white;
    width: 100vw;
    z-index: 100; 
  }
  
  .homepage-middle {
    top: 15%;
    width: 70%; 
    gap: 0.5rem;
  }

  .homepage-middle h1 {
    font-size: small; 
    margin: 7px 0 6px 0px;
  }

  .homepage-middle button {
    font-size: 0.4em;
    /* padding: 0; */
    /* width: 40%; */
  }

  .homepage-options {
    gap: 10px; 
    margin-top: -20px;
  }

  .searchbar {
    height: 10px;
    width: 50%;
    margin-top: -5px;
    padding: 5px;
  }
  
  .searchbar input::placeholder {
    font-size: 10px; 
    color: #888; 
  }
  .searchbar img{
    width: 15px;
  }
 
  
}
@media screen and (max-width:500px){
  .homepage-middle{
    top: 9%;
    gap: 0;
  }
  .homepage-middle button {
    font-size: 0.1em;
    /* padding: 0; */
    /* width: 40%; */
  }
}

.Footer{
    width: 100%;
    background: #F6F8FA;
    margin: 0 auto;
    max-width: 100%;
    margin-top: 20px;
}
.container6{
    width: 100%;
    background: #F9F9F9;
    margin:  auto;
    max-width: 100%;
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    margin-top: -20px;
  
}
.articles-container{
    width: 80%;
    align-items: center;
   justify-content: center;
    display: flex;
    text-align: center;
    gap: 10px;
    margin-left:140px;
    margin-top: -50px;
}

.article-image-card{
    width: 50%;
    background: #FFFFFF;
    padding: 10px;
    border-radius: 10px;
}
.article-image{
    width: 100%;
    border-radius: 10px;
}
.article-image-card button{
    border: none;
}
.article-image-card h6{
    color: grey;
}
.container7{
    width: 100%;
    background: #FFFFFF;
    margin: 30px auto;
    max-width: 100%;
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
  
}
.container7-top{
    margin: 50px 0;
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;

}
.container7-names{
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
    text-align: left;
    margin-right: 400px;




}
.data-container{
    display: flex;
    flex-direction: column;
    text-align: left;
}

.data-container h5 {
    margin: 10px;
    line-height: 0.8;
    font-weight: 300; 
}
.data-estates{
    padding-top: 20px;
    border-top: 1px solid lightgray;
    display: flex;
}
.container7-bottom{
    width: 90%;
    padding: 50px;
    background: #FFF8F6;
    display: flex;
    margin: 0 50px;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
}
.container7-bottom h5{
    font-weight: 300;
}

.container7-bottom button{
    background: #e7c873;
    margin: 0;
    padding: 10px;
    line-height: normal;
    width: 100%;
    height: 10%;
    border: none;
    border-radius: 10px;

}
.footer{
    width: 100%;
    background: #1A1A1A;
    margin: 0 auto;
    max-width: 100%;
    margin-top: 20px;
}
.footer-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 100px;
    color: #FFFFFF;
    border-bottom: 1px solid black;
}
.footer-top-left{
    display: flex;
    gap: 20px;
    align-items: center;
    
}
.footer-top-right{
    display: flex;
    gap: 20px;
    
}
.logo{
    width: 50%;
    height: 50%;
}
.footer-middle{
    display: flex;
    padding: 10px 100px;
    color: #FFFFFF;
    font-size: 15px;
    gap: 60px;
    border-bottom: 1px solid black;
}
.footer-middle-right{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.footer-middle-left{
    display: flex;
    justify-content: space-between;
}
.footer-middle-middle{
    display: flex;
    justify-content: space-between;
    gap: 30px;
}
.footer-middle-left button{
    border: none;
    background-color: gray;
    color: #FFFFFF;
    width: 30%;
    height: 100%;
    border-radius: 15px;
    padding: 10px;
}
.footer-bottom{
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer-middle h3{
    color: gray;
    width: 100%;
}
.footer-middle h4{
    font-weight: 300;
}

@media screen and (max-width:1200px) {
.footer-middle{
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-left: -80px;
}
.footer-middle-middle{
    flex-wrap: wrap;
    gap: 20px;
    width: 85%;
}
.container7-top{
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-right: auto;
}
.container7-names{
    margin-right: auto;
}
.data-estates{
    flex-wrap: wrap;
    width: 100%;

    justify-content: center;
    align-items: center;
    margin-right: auto; 
    gap: 20px;
}
.container6{
    width: 100%;
    flex-direction: column;
}
.articles-container{
    flex-direction: column;
    width: 100%;
    margin-left: 0;
}
.article-image-card{
    width: 100%;
}
}
@media screen and (max-width:600px) {
    .container6{
        text-align: center;
        justify-content: center;
        align-items: center;
    }
    .articles-container{
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-left: 0;
        justify-content: center;
    }
    .article-image-card{
        width: 100%;
    }
    .article-image{
        width: 80%;
    }
    .logo{
        width: 20%;
    }
   .footer-top-left{
    justify-content: space-between;
   }
   .footer-top{
    margin-left: -112px;
    width: 100%;
   }
   
    .container7-bottom{
        width: 60%;
        flex-direction: column;
        align-items: center;
    }
    .container7-bottom button{
        width: 100%;
    }
   
    .footer-top{
        flex-direction: column;
    }
    .footer-middle{
        width: 90%;
      
        margin-left: -86px;
    }
    .footer-middle-left button{

        width: 50%;
    }
    .footer-middle-left{
        width: 100%;
        align-items: center;
    }
    .container7-names{
        margin-left: auto;
    }
    .data-estates{
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-left: auto; 
        gap: 20px;
        flex-direction: column;
    }
    /* .footer-middle-middle{
        align-items: center;
        justify-content: center;
    } */
}